<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a"
            >点名认证统计</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start;">
          <div class="searchbox">
            <div title="学员姓名" class="searchboxItem ci-full">
              <span class="itemLabel">学员姓名:</span>
              <el-input
                v-model="ruleForm.username"
                type="text"
                size="small"
                placeholder="请输入学员姓名"
                clearable
              />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input
                v-model="ruleForm.idcard"
                type="text"
                size="small"
                placeholder="请输入身份证号"
                clearable
              />
            </div>
            <div title="手机号码" class="searchboxItem ci-full">
              <span class="itemLabel">手机号码:</span>
              <el-input
                v-model="ruleForm.mobile"
                type="text"
                size="small"
                placeholder="请输入手机号码"
                clearable
              />
            </div>
             <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="ruleForm.projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
          </div>
          <div class="searchbox" style="margin-top:10px">
           
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                v-model="ruleForm.projectCode"
                type="text"
                size="small"
                placeholder="请输入班级编码"
                onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                clearable
              />
            </div>
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="ruleForm.courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <div title="学习状态" class="searchboxItem ci-full">
              <span class="itemLabel">学习状态:</span>
              <el-select
                    class="listInput"
                    size="small"
                    v-model="ruleForm.classStatus"
                    placeholder="请选择学习状态"
                    clearable
                  >
                    <el-option
                      v-for="item in classStatusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
            </div>
            <div class="df" style="margin-left:10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                minWidth="150"
              />
              <el-table-column
                label="手机号码"
                align="left"
                show-overflow-tooltip
                prop="mobile"
                minWidth="120"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                minWidth="190"
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                minWidth="180"
              />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
                minWidth="120"
              />
              <el-table-column
                label="学习状态"
                align="center"
                prop="completeState"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.completeState != '40'">{{
                    $setDictionary("LEARNINGSTATE", scope.row.completeState)
                  }}</span>
                  <el-tooltip
                    v-else
                    class="item"
                    effect="dark"
                    :content="scope.row.notCompleteReasons"
                    placement="top"
                  >
                    <span
                      style="cursor: pointer; display: flex;justify-content: center;"
                      >{{
                        $setDictionary("LEARNINGSTATE", scope.row.completeState)
                      }}</span
                    >
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                label="完成学时"
                align="right"
                show-overflow-tooltip
                prop="studyLessonNum"
              />
              <el-table-column
                label="剩余学时"
                align="right"
                show-overflow-tooltip
                prop="residueLessonNum"
              />
              <el-table-column
                label="监管点名完成次数"
                align="right"
                show-overflow-tooltip
                width="70"
                prop="govRollCompleteNum"
              />
              <el-table-column
                label="监管点名剩余次数"
                align="right"
                width="70"
                show-overflow-tooltip
                prop="govRollResidueNum"
              />
              <el-table-column
                label="监管点名超时次数"
                align="right"
                width="70"
                show-overflow-tooltip
                prop="govRollTimeoutNum"
              />
              <el-table-column
                label="机构点名完成次数"
                align="right"
                width="70"
                show-overflow-tooltip
                prop="orgRollCompleteNum"
              />
              <el-table-column
                label="机构点名剩余次数"
                align="right"
                width="70"
                show-overflow-tooltip
                prop="orgRollResidueNum"
              />
              <el-table-column
                label="机构点名超时次数"
                align="right"
                width="70"
                show-overflow-tooltip
                prop="orgRollTimeoutNum"
              />
              <el-table-column
                label="随机点名完成次数"
                align="right"
                width="70"
                show-overflow-tooltip
                prop="randomRollCompleteNum"
              />
              <el-table-column
                label="随机点名剩余次数"
                align="right"
                width="70"
                show-overflow-tooltip
                prop="randomRollResidueNum"
              />
              <el-table-column
                label="随机点名失败次数"
                align="right"
                width="70"
                show-overflow-tooltip
                prop="randomRollTimeoutNum"
              />

              <el-table-column
                label="点名状态"
                align="center"
                show-overflow-tooltip
                prop="rollCallState"
              />
              <el-table-column
                label="操作"
                align="center"
                fixed="right"
              >
              <div slot-scope="scope" class="df">
                <el-button
                  style="padding:0 10px"
                  type="text"
                  size="mini"
                  @click="open(scope.row, '1', 'ywRoll')"
                >点名记录</el-button>
              </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";

export default {
  name: "operate/RollCallCertificationStatisticsYw",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      dialogVisible: false,
      userName: "",
      ruleForm: {
        username: "",
        mobile: "",
        idcard: "",
        projectName: "",
        projectCode: "",
        courseName: "",
        classStatus: "",
      },

      classStatusList: [], //
      areatreeList: [], //行政区域list
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  created() {},
  watch: {},
  computed: {},
  mounted() {
  },
  methods: {
    getLearningstateList() {
      const classStatusList = this.$setDictionary("LEARNINGSTATE", "list");
      const list = [];

      for (const key in classStatusList) {
        list.push({
          value: key,
          label: classStatusList[key],
        });
      }

      this.classStatusList = list;
    },
    init() {
      this.getTableHeight();
      this.getData(-1);
      this.getLearningstateList();
    },

    //跳转
    open(row, type, stu) {
      if (type == "1") {
        //认证统计

        this.$router.push({
          path: "/web/SupervisionEnd/sjzRegulator/rollStatistics",
          query: {
            projectId: row.projectId,
            userId: row.userId,
            userName: row.userName,
            idcard: row.idcard,
            mobile:row.mobile,
            stu
          },
        });
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.ruleForm.username) {
        params.userName = this.ruleForm.username;
      }
      if (this.ruleForm.mobile) {
        params.mobile = this.ruleForm.mobile;
      }
      if (this.ruleForm.idcard) {
        params.idcard = this.ruleForm.idcard;
      }
      if (this.ruleForm.projectName) {
        params.projectName = this.ruleForm.projectName;
      }
      if (this.ruleForm.projectCode) {
        params.projectCode = this.ruleForm.projectCode;
      }
      if (this.ruleForm.courseName) {
        params.courseName = this.ruleForm.courseName;
      }
      if (this.ruleForm.classStatus) {
        params.completeState = this.ruleForm.classStatus;
      }
      this.doFetch({
        url: "/gov/roll/call/user/page-list",
        params,
        pageNum,
      });
    },
    /* 获取高度 */
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1
      }
      if (page) {
        tHeight -= 60;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.operationControlCurr {
  
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .el-form {
    display: flex;
    .el-form-item__label {
      padding: 0 8px 0 0 !important;
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}
.operationControl {
  .searchboxItem  {
    margin-right: 0;
  }
}
</style>
